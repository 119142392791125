

















import SfNotification from '~/components/JetUi/molecules/SfNotification/SfNotification.vue';
import {
  defineComponent, ref, onMounted, onBeforeUnmount, useRouter,
} from '@nuxtjs/composition-api';
import { SfTopBar } from '@storefront-ui/vue';

export default defineComponent({
  components: {
    SfTopBar,
    SfNotification,
  },
  setup() {
    const visibilityCoupon = ref(false);
    const couponCode = ref('ESP10');
    const isTopBarHidden = ref(false);
    const router = useRouter();

    // const copyCoupon = () => {
    //   navigator.clipboard.writeText(couponCode.value).catch((err) => console.error('Failed to copy:', err));
    //   visibilityCoupon.value = true;

    //   setTimeout(() => {
    //     visibilityCoupon.value = false;
    //   }, 3000);
    // };

    const redirectToCouponPage = () => {
      router.push('/cupons');
    };

    const handleScroll = () => {
      isTopBarHidden.value = window.scrollY > 0;
    };

    onMounted(() => {
      window.addEventListener('scroll', handleScroll);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', handleScroll);
    });

    return {
      couponCode,
      visibilityCoupon,
      isTopBarHidden,
      redirectToCouponPage,
    };
  },
});
