







import { SfButton, SfIcon } from '@storefront-ui/vue';

import {
    ref,
    defineComponent,
    onMounted,
    onBeforeUnmount
} from '@nuxtjs/composition-api';
export default defineComponent({
    name: 'HeaderButton',
    components: {
        SfButton,
        SfIcon
    },
    setup() {
        const showBackToTop = ref(false);

        const handleScroll = () => {
            showBackToTop.value = window.scrollY > 20;
        };

        const scrollToTop = () => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        };

        onMounted(() => {
            window.addEventListener('scroll', handleScroll);
        });

        onBeforeUnmount(() => {
            window.removeEventListener('scroll', handleScroll);
        });
        return {
            scrollToTop,
            showBackToTop
        };
    },
});
