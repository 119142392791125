
























import LazyHydrate from 'vue-lazy-hydration';
import {
  useRoute, defineComponent, ref, onMounted, onBeforeUnmount, provide
} from '@nuxtjs/composition-api';
import { useUiState } from '~/composables';
import AppHeader from '~/components/AppHeader.vue';
import HeaderButton from '~/components/JetUi/atoms/FloatButtons/HeaderButton.vue';
import BottomNavigation from '~/components/BottomNavigation.vue';
import IconSprite from '~/components/General/IconSprite.vue';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import TopBar from '~/components/JetUi/atoms/topbar/TopBar.vue';
import FloatWppButton from '~/components/JetUi/atoms/FloatButtons/FloatWppButton.vue';

export default defineComponent({
  name: 'DefaultLayout',

  components: {
    LoadWhenVisible,
    LazyHydrate,
    AppHeader,
    BottomNavigation,
    IconSprite,
    TopBar,
    FloatWppButton,
    HeaderButton,
    AppFooter: () => import(/* webpackPrefetch: true */ '~/components/AppFooter.vue'),
    CartSidebar: () => import(/* webpackPrefetch: true */ '~/modules/checkout/components/CartSidebar.vue'),
    WishlistSidebar: () => import(/* webpackPrefetch: true */ '~/modules/wishlist/components/WishlistSidebar.vue'),
    LoginModal: () => import(/* webpackPrefetch: true */ '~/modules/customer/components/JetLogin/LoginModal.vue'),
    Notification: () => import(/* webpackPrefetch: true */ '~/components/Notification.vue'),
  },

  setup() {
    const header = ref(null);
    const headerHeight = ref(0);
    const route = useRoute();
    const username = ref('');
    const updateUsername = (name: string) => {
      username.value = name;
    }

    provide('username', { username, updateUsername });

    const {
      isCartSidebarOpen, isWishlistSidebarOpen, isLoginModalOpen, toggleLoginModal,
    } = useUiState();

    const updateHeaderHeight = () => {
      if (header.value) {
        headerHeight.value = header.value.offsetHeight;
      }
    };

    onMounted(() => {
      updateHeaderHeight();
      window.addEventListener('resize', updateHeaderHeight);
      window.addEventListener('scroll', updateHeaderHeight);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', updateHeaderHeight);
      window.addEventListener('scroll', updateHeaderHeight);
    });

    return {
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
      route,
      header,
      headerHeight,
    };
  },

  head() {
    return {
      meta: [
        { name: 'google-site-verification', content: 'MOnsMSVWFMlJ5n793bZfJ26PQ4w74ifhOSCBjENfqmw' }
      ],
      script: [
        {
          innerHTML: `  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-MMB2848K');`}
      ],
      noscript: [
        {
          innerHTML: `
          <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MMB2848K"
height="0" width="0" style="display:none;visibility:hidden"></iframe>
        `},
        {
          innerHTML: `
            <img height="1" width="1" style="display:none"
            src="https://www.facebook.com/tr?id=1462987271074223&ev=PageView&noscript=1"/>
          `,
        }
      ]
    };
  },
});
