import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _23f9e7e4 = () => interopDefault(import('../pages/AboutUs.vue' /* webpackChunkName: "pages/AboutUs" */))
const _bafb98ec = () => interopDefault(import('../server/blog/BlogView.vue' /* webpackChunkName: "" */))
const _15c093dc = () => interopDefault(import('../pages/Budget.vue' /* webpackChunkName: "pages/Budget" */))
const _bf29af0c = () => interopDefault(import('../modules/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _62e172c0 = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _7dfe933c = () => interopDefault(import('../modules/checkout/pages/Checkout/JetCheckout/Billing.vue' /* webpackChunkName: "" */))
const _79ed81f7 = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _fce3ea86 = () => interopDefault(import('../modules/checkout/pages/Checkout/JetCheckout/Shipping.vue' /* webpackChunkName: "" */))
const _94606098 = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _c6ca56ba = () => interopDefault(import('../modules/checkout/pages/Checkout/JetCheckout/UserAccount.vue' /* webpackChunkName: "" */))
const _1f796ccc = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _a385c2ba = () => interopDefault(import('../pages/Contact.vue' /* webpackChunkName: "pages/Contact" */))
const _13e5e910 = () => interopDefault(import('../pages/Coupons.vue' /* webpackChunkName: "pages/Coupons" */))
const _3bd0d07e = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _4503cc2f = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _66f537d5 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _4fe786eb = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _00915aaf = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _10cef3e1 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _6f08e16f = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _3ce7996a = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _1d08a03b = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _d7df1002 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _0226b3b8 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _7ae0447c = () => interopDefault(import('../pages/ProductReturn.vue' /* webpackChunkName: "" */))
const _398fc7ec = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _acd46fce = () => interopDefault(import('../pages/Faq.vue' /* webpackChunkName: "pages/Faq" */))
const _490431cf = () => interopDefault(import('../pages/ShippingDelivery.vue' /* webpackChunkName: "" */))
const _8f78d676 = () => interopDefault(import('../pages/Refund.vue' /* webpackChunkName: "" */))
const _ec7644c8 = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _d8f2a1e2 = () => interopDefault(import('../pages/ReturnPolicy.vue' /* webpackChunkName: "" */))
const _42f3bfc6 = () => interopDefault(import('../pages/PrivacyPolicy.vue' /* webpackChunkName: "" */))
const _e52109c0 = () => interopDefault(import('../pages/SearchResultsPage.vue' /* webpackChunkName: "pages/SearchResultsPage" */))
const _d980bebc = () => interopDefault(import('../pages/WorkWithUS.vue' /* webpackChunkName: "" */))
const _da2a32f0 = () => interopDefault(import('../pages/post/_slug.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/AboutUs",
    component: _23f9e7e4,
    name: "AboutUs___default"
  }, {
    path: "/blog",
    component: _bafb98ec
  }, {
    path: "/Budget",
    component: _15c093dc,
    name: "Budget___default"
  }, {
    path: "/cart",
    component: _bf29af0c,
    name: "cart___default"
  }, {
    path: "/checkout",
    component: _62e172c0,
    name: "checkout___default",
    children: [{
      path: "billing",
      component: _7dfe933c,
      name: "billing___default"
    }, {
      path: "payment",
      component: _79ed81f7,
      name: "payment___default"
    }, {
      path: "shipping",
      component: _fce3ea86,
      name: "shipping___default"
    }, {
      path: "thank-you",
      component: _94606098,
      name: "thank-you___default"
    }, {
      path: "user-account",
      component: _c6ca56ba,
      name: "user-account___default"
    }]
  }, {
    path: "/Cms",
    component: _1f796ccc,
    name: "Cms___default"
  }, {
    path: "/Contact",
    component: _a385c2ba,
    name: "Contact___default"
  }, {
    path: "/Coupons",
    component: _13e5e910,
    name: "Coupons___default"
  }, {
    path: "/cupons",
    component: _13e5e910,
    name: "cupons___default"
  }, {
    path: "/customer",
    component: _3bd0d07e,
    meta: {"titleLabel":"My Account"},
    name: "customer___default",
    children: [{
      path: "addresses-details",
      component: _4503cc2f,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___default"
    }, {
      path: "my-newsletter",
      component: _66f537d5,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___default"
    }, {
      path: "my-profile",
      component: _4fe786eb,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___default"
    }, {
      path: "my-reviews",
      component: _00915aaf,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___default"
    }, {
      path: "my-wishlist",
      component: _10cef3e1,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___default"
    }, {
      path: "order-history",
      component: _6f08e16f,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___default"
    }, {
      path: "/reset-password",
      component: _3ce7996a,
      alias: "/customer/account/createPassword",
      name: "reset-password___default"
    }, {
      path: "addresses-details/create",
      component: _1d08a03b,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___default"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _d7df1002,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___default"
    }, {
      path: "order-history/:orderId",
      component: _0226b3b8,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___default"
    }]
  }, {
    path: "/devolucao",
    component: _7ae0447c,
    name: "devolução___default"
  }, {
    path: "/en",
    component: _398fc7ec,
    name: "home___en"
  }, {
    path: "/fale-conosco",
    component: _a385c2ba,
    name: "contato___default"
  }, {
    path: "/Faq",
    component: _acd46fce,
    name: "Faq___default"
  }, {
    path: "/frete-entrega",
    component: _490431cf,
    name: "frete e entrega___default"
  }, {
    path: "/german",
    component: _398fc7ec,
    name: "home___german"
  }, {
    path: "/Home",
    component: _398fc7ec,
    name: "Home___default"
  }, {
    path: "/orcamento",
    component: _15c093dc,
    name: "orçamento___default"
  }, {
    path: "/pagamentos-e-reembolso",
    component: _8f78d676,
    name: "pagamento e reembolso___default"
  }, {
    path: "/Page",
    component: _ec7644c8,
    name: "Page___default"
  }, {
    path: "/politica-devolucao",
    component: _d8f2a1e2,
    name: "politica de troca e devolução___default"
  }, {
    path: "/privacidade",
    component: _42f3bfc6,
    name: "politica de privacidade___default"
  }, {
    path: "/PrivacyPolicy",
    component: _42f3bfc6,
    name: "PrivacyPolicy___default"
  }, {
    path: "/ProductReturn",
    component: _7ae0447c,
    name: "ProductReturn___default"
  }, {
    path: "/Refund",
    component: _8f78d676,
    name: "Refund___default"
  }, {
    path: "/ReturnPolicy",
    component: _d8f2a1e2,
    name: "ReturnPolicy___default"
  }, {
    path: "/SearchResultsPage",
    component: _e52109c0,
    name: "SearchResultsPage___default"
  }, {
    path: "/ShippingDelivery",
    component: _490431cf,
    name: "ShippingDelivery___default"
  }, {
    path: "/sobre-nos",
    component: _23f9e7e4,
    name: "sobre nós___default"
  }, {
    path: "/trabalhe-conosco",
    component: _d980bebc,
    name: "trabalhe conosco___default"
  }, {
    path: "/WorkWithUS",
    component: _d980bebc,
    name: "WorkWithUS___default"
  }, {
    path: "/en/AboutUs",
    component: _23f9e7e4,
    name: "AboutUs___en"
  }, {
    path: "/en/blog",
    component: _bafb98ec
  }, {
    path: "/en/Budget",
    component: _15c093dc,
    name: "Budget___en"
  }, {
    path: "/en/cart",
    component: _bf29af0c,
    name: "cart___en"
  }, {
    path: "/en/checkout",
    component: _62e172c0,
    name: "checkout___en",
    children: [{
      path: "billing",
      component: _7dfe933c,
      name: "billing___en"
    }, {
      path: "payment",
      component: _79ed81f7,
      name: "payment___en"
    }, {
      path: "shipping",
      component: _fce3ea86,
      name: "shipping___en"
    }, {
      path: "thank-you",
      component: _94606098,
      name: "thank-you___en"
    }, {
      path: "user-account",
      component: _c6ca56ba,
      name: "user-account___en"
    }]
  }, {
    path: "/en/Cms",
    component: _1f796ccc,
    name: "Cms___en"
  }, {
    path: "/en/Contact",
    component: _a385c2ba,
    name: "Contact___en"
  }, {
    path: "/en/Coupons",
    component: _13e5e910,
    name: "Coupons___en"
  }, {
    path: "/en/cupons",
    component: _13e5e910,
    name: "cupons___en"
  }, {
    path: "/en/customer",
    component: _3bd0d07e,
    meta: {"titleLabel":"My Account"},
    name: "customer___en",
    children: [{
      path: "addresses-details",
      component: _4503cc2f,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___en"
    }, {
      path: "my-newsletter",
      component: _66f537d5,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___en"
    }, {
      path: "my-profile",
      component: _4fe786eb,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___en"
    }, {
      path: "my-reviews",
      component: _00915aaf,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___en"
    }, {
      path: "my-wishlist",
      component: _10cef3e1,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___en"
    }, {
      path: "order-history",
      component: _6f08e16f,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___en"
    }, {
      path: "addresses-details/create",
      component: _1d08a03b,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___en"
    }, {
      path: "/en/reset-password",
      component: _3ce7996a,
      alias: "/customer/account/createPassword",
      name: "reset-password___en"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _d7df1002,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___en"
    }, {
      path: "order-history/:orderId",
      component: _0226b3b8,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___en"
    }]
  }, {
    path: "/en/devolucao",
    component: _7ae0447c,
    name: "devolução___en"
  }, {
    path: "/en/fale-conosco",
    component: _a385c2ba,
    name: "contato___en"
  }, {
    path: "/en/Faq",
    component: _acd46fce,
    name: "Faq___en"
  }, {
    path: "/en/frete-entrega",
    component: _490431cf,
    name: "frete e entrega___en"
  }, {
    path: "/en/Home",
    component: _398fc7ec,
    name: "Home___en"
  }, {
    path: "/en/orcamento",
    component: _15c093dc,
    name: "orçamento___en"
  }, {
    path: "/en/pagamentos-e-reembolso",
    component: _8f78d676,
    name: "pagamento e reembolso___en"
  }, {
    path: "/en/Page",
    component: _ec7644c8,
    name: "Page___en"
  }, {
    path: "/en/politica-devolucao",
    component: _d8f2a1e2,
    name: "politica de troca e devolução___en"
  }, {
    path: "/en/privacidade",
    component: _42f3bfc6,
    name: "politica de privacidade___en"
  }, {
    path: "/en/PrivacyPolicy",
    component: _42f3bfc6,
    name: "PrivacyPolicy___en"
  }, {
    path: "/en/ProductReturn",
    component: _7ae0447c,
    name: "ProductReturn___en"
  }, {
    path: "/en/Refund",
    component: _8f78d676,
    name: "Refund___en"
  }, {
    path: "/en/ReturnPolicy",
    component: _d8f2a1e2,
    name: "ReturnPolicy___en"
  }, {
    path: "/en/SearchResultsPage",
    component: _e52109c0,
    name: "SearchResultsPage___en"
  }, {
    path: "/en/ShippingDelivery",
    component: _490431cf,
    name: "ShippingDelivery___en"
  }, {
    path: "/en/sobre-nos",
    component: _23f9e7e4,
    name: "sobre nós___en"
  }, {
    path: "/en/trabalhe-conosco",
    component: _d980bebc,
    name: "trabalhe conosco___en"
  }, {
    path: "/en/WorkWithUS",
    component: _d980bebc,
    name: "WorkWithUS___en"
  }, {
    path: "/german/AboutUs",
    component: _23f9e7e4,
    name: "AboutUs___german"
  }, {
    path: "/german/blog",
    component: _bafb98ec
  }, {
    path: "/german/Budget",
    component: _15c093dc,
    name: "Budget___german"
  }, {
    path: "/german/cart",
    component: _bf29af0c,
    name: "cart___german"
  }, {
    path: "/german/checkout",
    component: _62e172c0,
    name: "checkout___german",
    children: [{
      path: "billing",
      component: _7dfe933c,
      name: "billing___german"
    }, {
      path: "payment",
      component: _79ed81f7,
      name: "payment___german"
    }, {
      path: "shipping",
      component: _fce3ea86,
      name: "shipping___german"
    }, {
      path: "thank-you",
      component: _94606098,
      name: "thank-you___german"
    }, {
      path: "user-account",
      component: _c6ca56ba,
      name: "user-account___german"
    }]
  }, {
    path: "/german/Cms",
    component: _1f796ccc,
    name: "Cms___german"
  }, {
    path: "/german/Contact",
    component: _a385c2ba,
    name: "Contact___german"
  }, {
    path: "/german/Coupons",
    component: _13e5e910,
    name: "Coupons___german"
  }, {
    path: "/german/cupons",
    component: _13e5e910,
    name: "cupons___german"
  }, {
    path: "/german/customer",
    component: _3bd0d07e,
    meta: {"titleLabel":"My Account"},
    name: "customer___german",
    children: [{
      path: "addresses-details",
      component: _4503cc2f,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___german"
    }, {
      path: "my-newsletter",
      component: _66f537d5,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___german"
    }, {
      path: "my-profile",
      component: _4fe786eb,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___german"
    }, {
      path: "my-reviews",
      component: _00915aaf,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___german"
    }, {
      path: "my-wishlist",
      component: _10cef3e1,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___german"
    }, {
      path: "order-history",
      component: _6f08e16f,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___german"
    }, {
      path: "addresses-details/create",
      component: _1d08a03b,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___german"
    }, {
      path: "/german/reset-password",
      component: _3ce7996a,
      alias: "/customer/account/createPassword",
      name: "reset-password___german"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _d7df1002,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___german"
    }, {
      path: "order-history/:orderId",
      component: _0226b3b8,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___german"
    }]
  }, {
    path: "/german/devolucao",
    component: _7ae0447c,
    name: "devolução___german"
  }, {
    path: "/german/fale-conosco",
    component: _a385c2ba,
    name: "contato___german"
  }, {
    path: "/german/Faq",
    component: _acd46fce,
    name: "Faq___german"
  }, {
    path: "/german/frete-entrega",
    component: _490431cf,
    name: "frete e entrega___german"
  }, {
    path: "/german/Home",
    component: _398fc7ec,
    name: "Home___german"
  }, {
    path: "/german/orcamento",
    component: _15c093dc,
    name: "orçamento___german"
  }, {
    path: "/german/pagamentos-e-reembolso",
    component: _8f78d676,
    name: "pagamento e reembolso___german"
  }, {
    path: "/german/Page",
    component: _ec7644c8,
    name: "Page___german"
  }, {
    path: "/german/politica-devolucao",
    component: _d8f2a1e2,
    name: "politica de troca e devolução___german"
  }, {
    path: "/german/privacidade",
    component: _42f3bfc6,
    name: "politica de privacidade___german"
  }, {
    path: "/german/PrivacyPolicy",
    component: _42f3bfc6,
    name: "PrivacyPolicy___german"
  }, {
    path: "/german/ProductReturn",
    component: _7ae0447c,
    name: "ProductReturn___german"
  }, {
    path: "/german/Refund",
    component: _8f78d676,
    name: "Refund___german"
  }, {
    path: "/german/ReturnPolicy",
    component: _d8f2a1e2,
    name: "ReturnPolicy___german"
  }, {
    path: "/german/SearchResultsPage",
    component: _e52109c0,
    name: "SearchResultsPage___german"
  }, {
    path: "/german/ShippingDelivery",
    component: _490431cf,
    name: "ShippingDelivery___german"
  }, {
    path: "/german/sobre-nos",
    component: _23f9e7e4,
    name: "sobre nós___german"
  }, {
    path: "/german/trabalhe-conosco",
    component: _d980bebc,
    name: "trabalhe conosco___german"
  }, {
    path: "/german/WorkWithUS",
    component: _d980bebc,
    name: "WorkWithUS___german"
  }, {
    path: "/en/blog/post/:slug",
    component: _da2a32f0
  }, {
    path: "/german/blog/post/:slug",
    component: _da2a32f0
  }, {
    path: "/blog/post/:slug",
    component: _da2a32f0
  }, {
    path: "/en/post/:slug?",
    component: _da2a32f0,
    name: "post-slug___en"
  }, {
    path: "/en/search/:query",
    component: _e52109c0,
    name: "search-results___en"
  }, {
    path: "/german/post/:slug?",
    component: _da2a32f0,
    name: "post-slug___german"
  }, {
    path: "/german/search/:query",
    component: _e52109c0,
    name: "search-results___german"
  }, {
    path: "/en/:slug+",
    component: _ec7644c8,
    name: "page___en"
  }, {
    path: "/german/:slug+",
    component: _ec7644c8,
    name: "page___german"
  }, {
    path: "/post/:slug?",
    component: _da2a32f0,
    name: "post-slug___default"
  }, {
    path: "/search/:query",
    component: _e52109c0,
    name: "search-results___default"
  }, {
    path: "/",
    component: _398fc7ec,
    name: "home___default"
  }, {
    path: "/:slug+",
    component: _ec7644c8,
    name: "page___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
