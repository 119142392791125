
































































import {
  SfLink, SfList, SfImage,
} from '@storefront-ui/vue';
import { defineComponent, ref, onMounted } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import type { CategoryTree } from '~/modules/GraphQL/types';
import { useUiHelpers } from '~/composables';
import type { ComponentTemplateRef } from '~/types/componentTemplateRef';
import escritorio from '~/static/banners/800x150.png';
import limpeza from '~/static/banners/800x150.png';
import papelaria from '~/static/banners/800x150.png';

export default defineComponent({
  name: 'HeaderNavigationSubcategories',
  components: {
    SfLink,
    SfList,
    SfImage,
  },
  props: {
    currentCategory: {
      type: Object as PropType<CategoryTree | null>,
      default: () => null,
    },
    hasFocus: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { getCatLink } = useUiHelpers();
    const lvl1CatRefs = ref<ComponentTemplateRef[]>();
    const lvl2CatRefs = ref<ComponentTemplateRef[]>();
    const lvl2GroupedCatRefs = ref<ComponentTemplateRef[][]>();

    const hasChildren = (category: CategoryTree) => Boolean(category?.children.length > 0);

    const getGroupedLvl2CatRefs = (): ComponentTemplateRef[][] => {
      let current = 0;
      const result: ComponentTemplateRef[][] = [];
      lvl1CatRefs.value.forEach((lvl1CatRef) => {
        const groupCount = Number(lvl1CatRef.$el.dataset.children);
        const group = lvl2CatRefs.value.slice(current, current + groupCount);
        result.push(group);
        current += groupCount;
      });

      return result;
    };

    let lvl1CatFocusIdx = 0;
    let lvl2CatFocusIdx = -1;

    const navRight = () => {
      if (lvl1CatRefs.value[++lvl1CatFocusIdx]) {
        lvl1CatRefs.value[lvl1CatFocusIdx].$el.focus();
        lvl2CatFocusIdx = -1;
        lvl2GroupedCatRefs.value = getGroupedLvl2CatRefs();
      } else {
        lvl1CatFocusIdx--;
      }
    };

    const navLeft = () => {
      if (lvl1CatRefs.value[--lvl1CatFocusIdx]) {
        lvl1CatRefs.value[lvl1CatFocusIdx].$el.focus();
        lvl2CatFocusIdx = -1;
        lvl2GroupedCatRefs.value = getGroupedLvl2CatRefs();
      } else {
        lvl1CatFocusIdx++;
      }
    };

    const navDown = () => {
      lvl2CatFocusIdx++;
      if (lvl2CatFocusIdx !== -1 && !lvl2GroupedCatRefs.value[lvl1CatFocusIdx][lvl2CatFocusIdx]) {
        lvl2CatFocusIdx--;
        return;
      }
      lvl2GroupedCatRefs.value[lvl1CatFocusIdx][lvl2CatFocusIdx].$el.focus();
    };

    const navUp = () => {
      if (lvl2CatFocusIdx > 0) {
        lvl2CatFocusIdx--;
        lvl2GroupedCatRefs.value[lvl1CatFocusIdx][lvl2CatFocusIdx].$el.focus();
        return;
      }

      if (lvl2CatFocusIdx === 0) {
        lvl1CatRefs.value[lvl1CatFocusIdx].$el.focus();
        lvl2CatFocusIdx = -1;

        return;
      }

      if (lvl2CatFocusIdx === -1) {
        emit('hideSubcategories');
      }
    };

    const setupNav = () => {
      lvl2CatFocusIdx = -1;
      lvl1CatRefs.value[lvl1CatFocusIdx].$el.focus();
      lvl2GroupedCatRefs.value = getGroupedLvl2CatRefs();
    };

    onMounted(() => {
      if (props.hasFocus) {
        setupNav();
      }
    });

    const sectionImages = {
      escritorio: `${escritorio}`,
      papelaria: `${papelaria}`,
      limpeza: `${limpeza}`,
    };

    const getCategoryImage = (category: CategoryTree) => {
      let imagePath = '';
      if (category.name.toUpperCase().includes('ESCRITÓRIO')) {
        imagePath = sectionImages.escritorio;
      } else if (category.name.toUpperCase().includes('PAPELARIA')) {
        imagePath = sectionImages.papelaria;
      } else if (category.name.toUpperCase().includes('ESCRITA')) {
        imagePath = sectionImages.limpeza;
      }
      return imagePath;
    };

    return {
      getCatLink,
      hasChildren,
      navRight,
      navLeft,
      navDown,
      navUp,
      setupNav,
      lvl1CatRefs,
      lvl2CatRefs,
      getCategoryImage,
    };
  },
});
