




























































































import {
  SfOverlay, SfHeader, SfButton, SfBadge,
} from '@storefront-ui/vue';

import {
  computed,
  ref,
  defineComponent,
  useRouter,
  useContext,
  onMounted,
  useFetch,
  inject,
  provide,
  Ref
} from '@nuxtjs/composition-api';
import HeaderNavigation from '~/components/JetUi/organisms/Header/HeaderNavigation.vue';
import { useCategory } from '~/modules/catalog/category/composables/useCategory';
import {
  useUiHelpers,
  useUiState,
} from '~/composables';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import { useUser } from '~/modules/customer/composables/useUser';
import { useWishlistStore } from '~/modules/wishlist/store/wishlistStore';
import type { CategoryTree, ProductInterface } from '~/modules/GraphQL/types';
import HeaderLogo from '~/components/HeaderLogo.vue';
import SvgImage from '~/components/General/SvgImage.vue';
import { useTopBar } from './TopBar/useTopBar';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import { useProduct } from '~/modules/catalog/product/composables/useProduct';
import { Products } from '~/modules/GraphQL/types';

export default defineComponent({
  components: {
    HeaderNavigation,
    SfHeader,
    SfOverlay,
    HeaderLogo,
    SvgImage,
    SfButton,
    SfBadge,
    SkeletonLoader,
    CurrencySelector: () => import('~/components/CurrencySelector.vue'),
    StoreSwitcher: () => import('~/components/StoreSwitcher.vue'),
    SearchBar: () => import('~/components/Header/SearchBar/SearchBar.vue'),
    SearchResults: () => import(
      /* webpackPrefetch: true */ '~/components/JetUi/organisms/searchResults/SearchResult.vue'
    ),
  },
  setup() {
    const router = useRouter();
    const { app } = useContext();
    const { toggleCartSidebar, toggleWishlistSidebar, toggleLoginModal } = useUiState();
    const { getProductList } = useProduct();
    const { setTermForUrl, getCatLink } = useUiHelpers();
    const { isAuthenticated, user, load } = useUser();
    const { loadTotalQty: loadCartTotalQty, cart } = useCart();
    const { loadItemsCount: loadWishlistItemsCount } = useWishlist();
    const { categories: categoryList, load: categoriesListLoad } = useCategory();
    const { username, updateUsername } = inject<{ username: Ref<string>; updateUsername: (name: string) => void; }>('username');
    const productSearchResults = ref<ProductInterface[] | null>(null);
    const { hasCurrencyToSelect, hasStoresToSelect } = useTopBar();
    const loading = ref(false);

    const searchTerms = ref('');
    const updateSearchTerms = async (term: any) => {
      loading.value = true;
      searchTerms.value = term;
      productSearchResults.value = null;
      if (term) {
        const productList: Products = await getProductList({
          pageSize: 12,
          search: term,
        }) as Products;
        productSearchResults.value = productList!.items;
      };
      loading.value = false;
    };

    provide('searchTerms', { searchTerms, updateSearchTerms, productSearchResults, loading });

    const isSearchOpen = ref(false);
    const wishlistStore = useWishlistStore();
    const wishlistItemsQty = computed(() => wishlistStore.wishlist?.items_count ?? 0);
    const wishlistHasProducts = computed(() => wishlistItemsQty.value > 0);
    const accountIcon = computed(() => (isAuthenticated.value ? 'profile_fill' : 'profile'));

    const categoryTree = ref<CategoryTree[]>([]);

    const handleAccountClick = async () => {
      if (isAuthenticated.value) {
        await router.push(app.localeRoute({ name: 'customer-my-profile' }));
      } else {
        toggleLoginModal();
      }
    };

    useFetch(async () => {
      await categoriesListLoad({ pageSize: 20 });

      categoryTree.value = categoryList.value?.[0]?.children
        .filter((category) => category.include_in_menu);

      categoryTree.value = categoryTree.value.reduce((acc: CategoryTree[], current) => {
        const existing = acc.find((item) => item.name === current.name);

        if (existing) {
          if (current.children.length > 0) {
            const index = acc.indexOf(existing);
            acc.splice(index, 1, current);
          }
        } else {
          acc.push(current);
        }

        return acc;
      }, []);

      categoryTree.value.sort((a, b) => ((a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));
    });

    onMounted(async () => {
      if (app.$device.isDesktop) {
        await loadCartTotalQty();
        await loadWishlistItemsCount();
      }
      await load();
      updateUsername(isAuthenticated.value && user.value ? `${user.value?.firstname}` : '');
    });

    return {
      accountIcon,
      cartTotalItems: computed(() => cart.value?.total_quantity ?? 0),
      categoryTree,
      getCatLink,
      handleAccountClick,
      isAuthenticated,
      isSearchOpen,
      username,
      setTermForUrl,
      toggleCartSidebar,
      toggleWishlistSidebar,
      wishlistHasProducts,
      wishlistItemsQty,
      hasCurrencyToSelect,
      hasStoresToSelect,
    };
  },
});
