






















import { SfLink, SfIcon } from '@storefront-ui/vue';
import { CategoryTree } from '~/modules/GraphQL/types';
import { defineComponent, ref, PropType } from '@nuxtjs/composition-api';
import MenuSubOptions from './MenuSubOptions.vue';

export default defineComponent({
  name: 'MenuOption',
  components: {
    SfLink,
    SfIcon,
    MenuSubOptions
  },
  props: {
    category: {
      type: Object as PropType<CategoryTree>
    },
    label: {
      type: String,
      default: '',
    },
    link: {
      type: [String, Object],
      default: '',
    },
    hasChildren: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const hasFocus = ref(false);
    const currentCategory = ref<CategoryTree | null>(null);

    const setCurrentCategory = (category: CategoryTree | null) => {
      currentCategory.value = category;
    };

    const onMouseEnter = (category: CategoryTree) => {
      currentCategory.value = category;
      hasFocus.value = true;
    };

    const onMouseLeave = () => {
      currentCategory.value = null;
      hasFocus.value = false;
    };

    const onMouseClickMobile = (category: CategoryTree, link: string, hasChildren: Boolean) => {
      onMouseEnter(category);
      if (!hasChildren) {
        window.open(link, '_self');
      }
    }

    const isMobile = () => {
      return window.innerWidth <= 768;
    }

    return {
      onMouseClickMobile,
      onMouseEnter,
      onMouseLeave,
      setCurrentCategory,
      isMobile,
      currentCategory,
      hasFocus,
    };
  },
});
