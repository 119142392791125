


























import {
  defineComponent, PropType,
} from '@nuxtjs/composition-api';
import {SfSidebar, SfButton, SfIcon} from '@storefront-ui/vue';
import {CategoryTree} from '~/modules/GraphQL/types';
import {useUiHelpers} from '~/composables';
import MenuOption from './MenuOption.vue';

export default defineComponent({
  name: 'MenuDrawer',
  components: {
    SfSidebar,
    SfButton,
    SfIcon,
    MenuOption
  },
  props: {
    customLinks: {
      type: Array,
      default: []
    },
    categoryTree: {
      type: Array as PropType<CategoryTree[]>,
      default: () => [],
    },
    isDrawerOpen: {
      type: Boolean,
      required: true,
    }
  },
  setup(_, {emit}) {
    const {getCatLink} = useUiHelpers();
    const closeDrawer = () => {
      emit('close');
    };

    const isMobile = () => {
      return window.innerWidth <= 768;
    }

    const onCustomLinkClick = (link: string) => {
      window.open(link, '_blank');
    }

    const hasChildren = (category: CategoryTree) => Boolean(category?.children?.length);

    return {
      isMobile,
      closeDrawer,
      hasChildren,
      getCatLink,
      onCustomLinkClick
    };
  },
});
