import { ItauPixQrCodeResponse, ItauPixRequest, ItauPixResponse } from './types/PixTypes';
import { JetPaymentApi } from './JetPaymentApiInstance';

class PixService {
  // Consultar um lançamento PIX por ID
  async consultarLancamentoPix(orderId: string): Promise<any> {
    try {
      const response = await JetPaymentApi.get('/itau/pix/transactions', {params: {orderId}});
      return response.data;
    } catch (error) {
      throw new Error(`Erro ao consultar o lançamento PIX: ${error}`);
    }
  }

  // Emissão de cobrança imediata PIX
  async emitirCobrancaImediata(data: ItauPixRequest, order_id: string): Promise<ItauPixResponse> {
    try {
      const response = await JetPaymentApi.post('/itau/pix/transactions', data, {params: {orderId: order_id}});
      return response.data;
    } catch (error) {
      throw new Error(`Erro ao emitir cobrança imediata PIX: ${error}`);
    }
  }

  async emitirQrCode(txid: string): Promise<ItauPixQrCodeResponse> {
    try {
      const response = await JetPaymentApi.get(`/itau/pix/qrcode?txid=${txid}`);
      return response.data;
    } catch (error) {
      throw new Error(`Erro ao emitir QR Code PIX: ${error}`);
    }
  }
}

export default new PixService();
